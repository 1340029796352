@import '../../../../Fields/GenericFields';

.anchors-form {
  @extend %order-form-section;

  &__title {
    @extend %order-form-section__title;
  }

  &__hr {
    @extend %hr;

    margin-top: 20px;
  }
}
