@import '../../../../Fields/GenericFields';

.feature {
    margin-top: 24px;

    &__title {
        @extend %order-form-section__title
    }

    &__image {
        width: 100%;
    }
}

.featureIcon {
    color: #ff9e00;
    font-size: 20px;
    align-items: center;
    display: flex;
}

.featureWarning {
    color: #003057;
    font-weight: bold;
    align-items: center;
    display: flex;
}

.featureContainer {
    margin-Top: 10px;
    display: flex;
}