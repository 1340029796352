.admin {
    height: 99%;
}

.admin-content {
    height: 95%;
}

.tab-bar {
    border: none;
    box-shadow: none;
    padding: 0;
    height: 100%;

    .ui.pointing.menu {
        border: 1px solid rgba(34,36,38,.15);
        border-radius: 0.28571429rem;
        box-shadow: 0 1px 2px 0 rgba(34,36,38,.15);
    }
}

.admin-tab {
    padding: 0px;
    border: none;
    box-shadow: none;
    height: 100%
}

.dealer-modal {
    height: 90% !important;
}

.ui.segment {
    .admin-table {
        height: 100%;
        border: none;
        box-shadow: none;

        .ui.selection.dropdown {
            width: 20%;
        }
    }
}

.dealer-scroll {
    height: 85%;
    border-bottom: 1px solid rgba(34,36,38,.15);
    overflow: auto;
}

.data-content {
    height: 92%;
}

.project-modal {
    height: 90%;

    .admin-table {
        height: 100%;
        border: none;
        box-shadow: none;

        .data-scroll {
            height: 90%;
            overflow: auto;
            border-bottom: 1px solid rgba(34,36,38,.15);
        }
    }
}

.ui.large.header.table-header {
    display: inline-block;
}

.export-button {
    float: right;
}

.project-scroll {
    height: 82%;
    overflow: auto;
    border-bottom: 1px solid rgba(34,36,38,.15);
}

.data-scroll {
    height: 82%;
    overflow: auto;
    border-bottom: 1px solid rgba(34,36,38,.15);
}

@media screen and (width < 1230px) and (width > 700px) {
    .data-scroll {
        height: 70%;
        overflow: auto;
        border-bottom: 1px solid rgba(34,36,38,.15);
    }
}

@media screen and (width < 700px) and (width > 600px) {
    .data-scroll {
        height: 62%;
        overflow: auto;
        border-bottom: 1px solid rgba(34,36,38,.15);
    }
}

@media screen and (width < 600px) {
    .data-scroll {
        height: 62%;
        overflow: auto;
        border-bottom: 1px solid rgba(34,36,38,.15);
    }

    .ui.selection.dropdown {
        min-width: 5em;
    }
}

.order-icon {
    display: inline;
    width: 10px;
    height: 10px;
}

.order-info {
    position: absolute;
}

.order-icon:hover .order-tooltiptext {
    visibility: visible;
  }

.tooltiptext {
    visibility: hidden;
    font-size: 13px;
    background-color: #93999d;
    color: #FFFFFF;
    text-align: center;
    border-radius: 6px;
    position: absolute;
    z-index: 10;
    top: -55px;
    left: 145px;
    padding: 5px;
}

.project-tooltiptext {
    visibility: hidden;
    font-size: 13px;
    background-color: #93999d;
    color: #FFFFFF;
    text-align: center;
    border-radius: 6px;
    position: absolute;
    z-index: 10;
    top: -13px;
    left: 120px;
    padding: 5px;
}

.pie-chart {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pie-chart-title {
    font-size: 16px;
    margin: 0;
}

.bar-chart-tooltip {
    background: white;
    border: 1px solid #c1c1c1;
    min-width: 100px;
    padding: 15px;
    display: flex;
    flex-direction: column;
}

.bar-chart-tooltip-title {
    font-weight: bold;
}
