body {
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
p,
div,
span {
  color: var(--color-blue);
  font-weight: 500;
}

input {
  color: var(--color-black) !important;
}

label {
  color: var(--color-gray-dark) !important;
}

table.selectable tr:hover,
input[type='date'] {
  cursor: pointer;
}
td {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
}
.placeholder,
.default.text {
  font-size: 0.9rem;
  color: var(--color-gray-dark) !important;
}

.layout {
  display: grid;
  grid-template-rows: var(--header-height) 1fr;
  grid-template-columns: var(--side-width-md) 1fr;
  grid-template-areas:
    'side header'
    'side content'
    'side footer';
  width: 100%;
  height: 100%;
  min-height: 85vh;
}

// mobile first
@media screen and (min-width: 950px) {
  .layout {
    grid-template-columns: var(--side-width) 1fr;

    aside.side {
      .ui.vertical.menu .item > i.icon {
        margin-right: 15px;
      }
    }
  }
}

.layout > .header {
  grid-area: header;
  border-bottom: 1px solid #ccc;
}

.layout > .content {
  grid-area: content;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  padding: var(--space);
  background-color: var(--color-gray-light);
}

.layout .side {
  grid-area: side;
  background-color: var(--color-blue);
  overflow: hidden;

  .ui.vertical.menu .item > i.icon {
    margin-right: 3em;
  }
}

.no-border::before {
  content: initial !important;
}

.main-action {
  position: absolute;
  right: 2em;
  top: 2em;
  z-index: 1;
}

.controls {
  display: flex;
  row-gap: var(--space);
  flex-flow: wrap;
}

.controls .control-left {
  margin-right: auto;
}
.controls .control-right {
  margin-left: auto;
}
.controls > *:not(:last-child) {
  margin-right: var(--space) !important;
}

@media screen and (max-width: 950px) {
  .row {
    flex-flow: wrap;
    gap: var(--space);
  }
}

.row {
  display: flex;
}
.row > .col {
  flex-basis: 100%;
}
.row > .col:not(:last-child) {
  margin-right: 2%;
}

.drop-target {
  flex-shrink: 0;
  height: 120px;
  width: 210px;
  padding: var(--half-space);
  color: var(--color-gray-dark);
  background-color: var(--color-gray-light);
  border: 2px dashed var(--color-gray-mid);
  margin-right: var(--half-space);
  margin-bottom: 20px;
  cursor: pointer;
}

.dealer-dropdown-btn {
  margin-left: auto;
  margin-top: 10px;
}

.thumb {
  position: relative;
  height: 120px;
  filter: drop-shadow(2px 2px 2px var(--color-black));
  cursor: pointer;
}
.thumb:not(:last-child) {
  margin-right: var(--space);
}
.thumb .delete {
  position: absolute;
  top: 4px;
  right: 0;
  color: var(--color-black);
  background-color: var(--color-white-trans);
  border: 1px solid var(--color-black);
}
.thumb .delete:hover {
  color: var(--color-red);
  background-color: var(--color-white);
  border-color: var(--color-red);
}

svg.empty {
  position: sticky;
  top: 50%;
  left: 50%;
  transform: translate(-60px, 0px);
}

.capitalize {
  .text {
    text-transform: capitalize;
  }
}

svg.cross {
  margin: auto;
}

img.error {
  outline: 2px solid var(--color-red);
}

.toast {
  position: fixed;
  top: 0;
  margin: 40px auto 0 auto;
  left: 30vw;
  max-width: 40vw;
  z-index: 1001 !important;
}

.toast.active {
  bottom: var(--double-space);
}

.chip {
  color: var(--color-white) !important;
  background-color: var(--color-gray-dark);
  padding: var(--half-space) var(--space);
  border-radius: 2rem;
  text-align: center;
  text-transform: capitalize;
}

.chip.primary {
  background-color: var(--color-medium-aquamarine);
}

.chip.approved {
  background-color: #7dcc66;
}

.chip.review {
  background-color: #ffde3c;
  color: rgba(0,0,0,.67) !important;
}

.chip.ordered {
  background-color: var(--color-blue);
}

.chip.completed {
  font-size: 13px;
}

.chip.assigned {
  background-color: #05b9e0;
}

/* Dealer Detail Page & Ordering */

.card-group {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.archive-button {
  background: none;
  border: none;
  padding-bottom: 15px;
  cursor: pointer;
  color: #009cbd;
  text-decoration: underline;
}
.image-grid .content {
  overflow: auto;
  padding-bottom: 20px;
}
.image-grid .grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 3%;
  width: 100%;
  padding: 3px;
}
.image-grid .swatch,
.review .swatch {
  position: relative;
  height: calc(var(--font-size) * 10);
  max-width: calc(var(--font-size) * 10);
  background-size: cover;
  background-repeat: no-repeat;
}
.image-grid .grid label {
  margin-top: var(--half-space);
}
.image-grid .swatch:hover,
.image-grid .selected {
  outline: 3px solid var(--color-orange);
}
.image-grid .selected::after {
  content: '✓';
  position: absolute;
  bottom: 0;
  right: 0;
  height: 2rem;
  width: 2rem;
  font-size: 1.2rem;
  text-align: center;
  line-height: 1.8;
  margin: var(--half-space);
  border-radius: 50%;
  color: var(--color-white);
  background-color: var(--color-orange);
  outline: 2px solid var(--color-white);
}
.image-grid.invalid {
  outline: 1px solid var(--color-red);
}

.stepper {
  display: flex;
}
.stepper > .content {
  width: 100%;
  overflow: auto;
}

.activated {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  padding-bottom: var(--double-space);
}

.activated > .field {
  position: absolute;
  bottom: 0;
}

input[type='number'] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
.number-input {
  display: inline-flex;
}
.number-input input[type='number'] {
  max-width: 5rem;
  height: 2.5rem;
  border: solid var(--color-gray-mid) !important;
  border-width: 1px 0 !important;
  border-radius: 0 !important;
  font-weight: 900;
  text-align: center;
}
.number-input button {
  position: relative;
  width: 2.5rem;
  height: 2.5rem;
  align-items: center;
  justify-content: center;
  appearance: none;
  -webkit-appearance: none;
  background-color: transparent;
  margin: 0;
  border: 1px solid var(--color-gray-mid);
  outline: none;
  cursor: pointer;
}
.number-input button:after {
  content: '-';
  display: inline-block;
  position: absolute;
  font-size: 2rem;
  transform: translate(-50%, -50%);
}
.number-input button.plus:after {
  content: '+';
}

.review {
  label {
    font-size: 16px;
    font-weight: bold;
    line-height: 16px;
    color: var(--color-blue) !important;
  }
  .value {
    font-size: 16px;
    margin: 4px 0 16px 0;
    color: var(--color-gray-mid) !important;
  }
  h3 {
    color: var(--color-gray-mid) !important;
  }
  .review-step:not(:last-child) {
    margin-bottom: var(--triple-space);
  }
  .review-header {
    color: var(--color-blue) !important;
    margin-bottom: 16px;
    font-size: 24px;
    i {
      color: var(--color-teal) !important;
    }
  }
}

.purchase-partner-group {
  display: flex !important;
}

.purchase-partner-card {
  margin: 10px 10px 0 0 !important;
}

.section-label {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 8px;
}

section h3 {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
}

.tos-container {
  height: 100vh;
  width: 100%;
}

.tos-document {
  height: 100%;
  width: 100%;
}
