#root {
  height: 100%;
}

:root {
  --font-size: 14px;
  --space: 1.25em;
  --half-space: calc(var(--space) / 2);
  --double-space: calc(var(--space) * 2);
  --triple-space: calc(var(--space) * 3);
  --quad-space: calc(var(--space) * 4);
  --header-height: 4em;
  --footer-height: 60px;
  --side-width-md: 3.75em;
  --side-width: 12em;
  --color-blue: #003057;
  --color-teal: #009cbd;
  --color-red: #db2828;
  --color-orange: #ff8672;
  --color-white: #fff;
  --color-black: #111;
  --color-gray-light: #eee;
  --color-gray-mid: #bbb;
  --color-gray-dark: #777;
  --color-white-trans: #fffa;
  --color-black-trans: #111a;
  --color-medium-aquamarine: #66ccaa;
  font-size: var(--font-size);
}
:not(:root):fullscreen::backdrop {
  background-color: #000a;
}

$color-gray-1: #e5e5e5;
$color-gray-2: #c4c4c4;
$color-gray-3: #666;
