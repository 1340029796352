$screen-boundary: 93rem;

.project-measurement-card-group {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  gap: 20px;
}

.feature-card-group {
  display: flex;
  @media (min-width: $screen-boundary) {
    flex-direction: row;
  }
  @media (max-width: $screen-boundary) {
    flex-direction: column;
  }
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
}

.cover-card-group {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
}

.vertical-line {
  width: 1px;
  background-color: var(--color-gray-mid);
}

.liner-card-group {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
}

.feature-header {
  color: var(--color-blue) !important;
  margin-bottom: -10px !important;
}
.ui.card.select-pool-card{
    display: grid;
    width: 26.75rem;
    height: 27.3125rem;
    max-height: 27.3125rem;
    padding: 1.25rem;
}
