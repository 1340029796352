@import '../../Fields/GenericFields';

$checkboxBackgroundActive: #009ABF;
$checkboxBorderColor: #7E8FA4;
$checkboxBackgroundDisabled: #9ed0df;

.managed-checkbox {
  width: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  margin-top: 16px;

  &--error {

  }

  &__copy {
    @extend %input-label--text;

    &--error {
      @extend %input-label--error
    }
  }

  &__copy-component {
    @extend %input-label--text;
    font-weight: normal;

    a {
      @extend %input-label--font-color;
      font-weight: bold;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }


  &__input {
    appearance: none;

    &:checked + .managed-checkbox__input-display {
      background-color: $checkboxBackgroundActive;
      border: 2px solid $checkboxBackgroundActive;

      &::after {
        opacity: 1;
      }
    }

    &:disabled + .managed-checkbox__input-display {
      background-color: $checkboxBackgroundDisabled;
      border: 2px solid $checkboxBackgroundDisabled;

      &::after {
        opacity: 1;
      }
    }
  }

  &__input-display {
    border: 2px solid $checkboxBorderColor;
    border-radius: 3px;
    width: 32px;
    height: 32px;
    display: block;
    position: relative;
    flex: 0 0 auto;
    transition: 300ms ease-in-out background-color;
    margin-right: 20px;

    &::after {
      width: 100%;
      height: 100%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      position: absolute;
      content: '';
      background-image: url(../../../../../src/img/image-select-check.svg);
      background-repeat: no-repeat;
      background-position: center;
      opacity: 0;
      transition: 300ms ease-in-out opacity;
    }
  }
}
