@import '../../styles/variables.scss';

.smart-table {
  &__sort-icon {
    color: $color-gray-2;
    padding-left: 0.5em;
  }
}

.table__image{
  max-width:200px;
  height:200px;
  object-fit:cover;
}

.table__nested-link{
  display: flex;
}

.sticky-header {
  top: 0;
  position: sticky;
}
