.project-form {
  overflow: auto;
}

.pads-container {
  display: flex;
  gap: 20px;
  flex-direction: column;

  .pad {
    flex: 1;
    margin: 0;
  }
}

@media screen and (min-width: 950px) {
  .pads-container {
    flex-direction: row;
  }
}
