.brand-title {
  color: var(--color-gray-dark)!important;
}

.brand-container {
  margin: 5px 0 10px 10px;
}

.brand-name {
  color: var(--color-gray-mid)!important;
}
