@import '../../Fields/GenericFields';

.managed-select {
  width: 100%;
  cursor: pointer;
  display: block;

  &--error {

  }

  &__copy {
    @extend %input-label;

    &--error {
      @extend %input-label--error
    }
  }

  &__select-wrap{
    @extend %select-wrap;
  }

  &__select {
    @extend %select;

    &--error {
      @extend %select--error
    }
  }
}
