@import '../../../../Fields/GenericFields';

.deck {
    margin-top: 24px;
    
    &__title {
        @extend %order-form-section__title;
    }

    &__image {
        width: 100%;
    }
}

.rdmIcon {
    color: #ff9e00;
    font-size: 20px;
    align-items: center;
    display: flex;
}

.rdmWarning {
    color: #003057;
    font-weight: bold;
    align-items: center;
    display: flex;
}

.rdmContainer {
    margin-Top: 10px;
    display: flex;
}