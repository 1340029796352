.footer {
  background: rgba(255, 255, 255, 0.6);
  display: flex;
  justify-content: center;
  font-size: 14px;
  padding: 22px 3.4%;
  border-top:1px solid #ccc;

  &__copy {
    color: #009CBD;
  }
}
