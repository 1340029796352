@import '../../../Fields/GenericFields';

.project-form {
    @extend %order-form-section;
  
    &__title {
      @extend %order-form-section__title;
    }

    &__second-title {
      margin-top: 25px;
    }

    &__two-column {
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: 1fr;
      gap: 15px;
    }
  }