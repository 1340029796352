
#pool{
    position: relative;
    overflow: hidden;
    margin: 0 auto 32px auto;
}

.loader {
    position: relative;
    display: flex;
    flex-direction: column;
    top:300px;
    height: 0% !important;
}

.loader__content{
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 50%;
    align-items: center;
   
}

.loader-text{
    color:var(--color-gray-dark);
    font-size:18px;
    &:last-of-type{
        margin-bottom: 32px;
    }
}

.loader-top {
    top: 0;
}
