@import '../../../Fields/GenericFields';

.spec-form {
    @extend %order-form-section;
  
    &__title {
      @extend %order-form-section__title;
    }

    &__title-collapsed {
      @extend %order-form-section__title;
      color: #6d757c;
      margin-bottom: 25px;
      margin-top: 25px;
      font-size: 22px;
      cursor: pointer;
    }

    &__title-active {
      @extend %order-form-section__title;
      margin-top: 25px;
      font-size: 22px;
      cursor: pointer;
    }

    &__second-title {
      margin-top: 10px;
      font-size: 20px;
    }

    &__two-column {
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: 1fr;
      gap: 15px;
    }

    &__address {
      &--title {
        @extend %input-label;
      }
    }

    &__modal {
     padding: 40px;
    }
  }