@import '../../Fields/GenericFields';

.carousel-image-picker {
  width: 100%;
  cursor: pointer;
  display: block;

  &__copy {
    @extend %input-label;

    &--error {
      @extend %input-label--error
    }
  }

  &__img-label-wrap {
    position: relative;
    padding-bottom: 100%;
    border: 2px solid transparent;

    &:before, &:after {
      content: '';
      width: 32px;
      height: 32px;
      border-radius: 50%;
      opacity: 0;
      transition: 300ms ease-in-out opacity;
      position: absolute;
      bottom: 16px;
      right: 16px;
    }

    &:before {
      border: 2px solid white;
      background-color: #FF8672;
      z-index: 1;
    }

    &:after {
      background-image: url(../../../../../src/img/image-select-check.svg);
      background-position: center;
      background-repeat: no-repeat;
      z-index: 2;
    }

    &--selected {

      border: 2px solid orange;

      &:before {
        opacity: 1;
      }

      &:after {
        opacity: 1
      }
    }
  }

  &__img-wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__img {
    object-fit: cover;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .swiper-container {
    width: 461px;
    display: block;
  }
  
  .swiper-button-prev, .swiper-button-next {
    color: #003057;
  }
}
