/* Utility classes */

.rel {
  position: relative;
}
.abs {
  position: absolute;
}
.flex {
  display: flex;
}
.grid {
  display: grid;
  grid-template-columns: auto auto;
}
.flex-col {
  flex-direction: column;
}
.flex-row {
  flex-direction: row;
}
.iflex {
  display: inline-flex;
}
.iblock {
  display: inline-block;
}
.none {
  display: none;
}
.centerv {
  align-items: center;
}
.centerh {
  justify-content: center;
}
.center {
  align-items: center;
  justify-content: center;
}
.capitalize {
  text-transform: capitalize;
}
.pad {
  padding: var(--space);
}
.pad- {
  padding: var(--half-space);
}
.pad2 {
  padding: var(--double-space);
}
.pad3 {
  padding: var(--triple-space);
}
.pad4 {
  padding: var(--quad-space);
}
.margin {
  margin: var(--space) !important;
}
.mtop {
  margin-top: var(--space) !important;
}
.mbottom {
  margin-bottom: var(--space) !important;
}
.mleft {
  margin-left: var(--space) !important;
}
.mright {
  margin-right: var(--space) !important;
}
.mauto {
  margin: auto !important;
}
.flow-auto {
  overflow: auto;
}
.flow-hidden {
  overflow: hidden;
}
.not-visible {
  visibility: hidden;
}
.pointer {
  cursor: pointer;
}
.top {
  top: 0;
}
.bottom {
  bottom: 0;
}
.left {
  left: 0;
}
.right {
  right: 0;
}
.floatl {
  float: left;
}
.floatr {
  float: right;
}
.fullh {
  height: 100%;
}
.fullw {
  width: 100%;
}
.textc {
  text-align: center;
}
.hidden {
  transform: scale(0);
  transition: all 0.1s, transform 0.2s !important;
  z-index: 1;
}
.max-height {
  max-height: 850px;
}
.show-children:hover > .hidden,
.show.hidden {
  transform: scale(1);
}
.scroll {
  height: 75vh;
  overflow: auto;
}
