@import '../../Fields/GenericFields';

.managed-input {
  width: 100%;
  cursor: pointer;
  display: block;
  margin-bottom: 1em;

  &--error {

  }

  &__copy {
    @extend %input-label;

    &--error {
      @extend %input-label--error
    }
  }

  &__input {
    @extend %input;

    &--error {
      @extend %input--error
    }
  }
}
