.image-select-card {
  position: relative;
  background-color: #e5e5e5;
  width: 100%;
  height: 200px;
  margin-top: 14px;
  border-radius: 10px;
  display: flex;
  cursor: pointer;
}

.ui.image.image-contianer {
  flex: 1;
  box-shadow: none;
  cursor: default;
  width: 200px;
}

.image-title {
  position: relative;
  float: right;
  top: 50%;
  height: 50px;
  flex: 20;
}

.image-select-radio {
  position: relative;
  float: right;
  top: 50%;
  height: 50px;
  flex: 1,
}

.ui.card:first-child {
  max-height: 350px
}

.header.shape-card-header {
  font-size: 2rem !important;
  overflow-wrap: break-word;
  color: #7F7F7F !important;
  line-height: 2.386875rem !important;
  margin: 0 !important;
  font-weight: 510;
  text-align: start;
  display: flex;
  align-items: center; /* 垂直居中 */
}

.shape-card-container {
  display: grid;
  width: 21.875rem;
  height: 16.75rem;
  margin: 0.625rem;
  cursor: pointer;
}

.select-pool-shape {
  color: #000000;
  text-align: center;
  font-size: 2rem;
  align-self: self-end;
  font-weight: 400;
}
.shape-button {
  margin-left: 0px;
}

.ui.image.shape-card-image {
  height: 15.625rem;
  box-shadow: none;
  cursor: default;
  justify-self: center;
  align-self: center;
}

.shape-card-image-cross{
   justify-self: center;
   align-self: center;
   width: 8.6875rem;
   height: 8.875rem;
}

.ui.selection.dropdown.pool-shape-dropdown {
  border: 1px solid rgba(34,36,38,.15);
  border-radius: 0.28571429rem;
  margin-left: 10px;
}
