$labelFontColor: #003057;
$errorRed: #FF3B30;

%order-form-section {
  margin-top: 25px;
  margin-left: 25px;
  padding-bottom: 15px;

  &__title {
    font-weight: 500;
    font-size: 24px;
  }
}

%input-label--font-color {
  color: $labelFontColor;
}

%input-label--text {
  @extend %input-label--font-color;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
}

%input-label {
  @extend %input-label--text;
  display: block;
  margin-bottom: 6px;
  margin-top: 12px;

  &--error {
    //color: inherit;
  }
}

%input-error {
  font-size: 13px;
  color: $errorRed;
  display: none;
}

%input-error--active {
  display: inline-block;
}

%input {
  appearance: none;
  border: 1px solid #7E8FA4;
  width: 100%;
  padding: 12px 12px 12px 12px;

  &--error {
    border: 1px solid $errorRed;
    color: $errorRed;
  }
}

%select {
  @extend %input;

  &--error {
    border: 1px solid $errorRed;
    color: $errorRed;
  }
}

%select-wrap {
  position: relative;
  overflow: hidden;

  &:after {
    content: '';
    pointer-events: none;
    position: absolute;
    right: 16px;
    top: 0;
    bottom: 0;
    background-image: url("../../../../src/img/select-chevron.svg");
    width: 18px;
    height: 19px;
    background-repeat: no-repeat;
    background-position: center;
  }
}

%hr {
  border-bottom: 1px solid #BCC5D2;
  border-top: none;
}
