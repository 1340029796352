/* Carousel */

.carousel {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    padding: 2px 8px 8px 5px;
    scroll-padding: 5px;
}
.carousel::-webkit-scrollbar {
    height: 0px;
    width: 0px;
}
.carousel:hover::-webkit-scrollbar {
    height: 10px;
    width: 10px;
}
.carousel::-webkit-scrollbar-thumb {
    background: var(--color-gray-mid);
    border-radius: 10px;
}
.carousel::-webkit-scrollbar-track {
    background: transparent;
}
.carousel > * {
    position: relative;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    scroll-snap-align: start;
    transform: scale(1);
    transform-origin: center center;
    transition: transform 0.5s;
}
