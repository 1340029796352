$menuInactiveColor: #7E8FA4;
$menuActiveColor: #009ABF;
$menuBorderActiveColor: #009CBD;

// Still need to overwrite the over zealous specificity in the semantic library
.vertical.menu.ui.modal-navigation {
  display: flex;
  border: 1px solid #CDDCE8;
  border-radius: 0;
  border-bottom: none;
  margin-bottom: 0;
  box-shadow: none;
  height: 100%;

  .modal-navigation__item {
    border-radius: 0;
    display: flex;
    align-items: center;
    min-height: 56px;
    font-size: 15px;
    padding-bottom: 16px;
    font-weight: 400;
    color: $menuInactiveColor;

    // Icon styles
    i.icon {
      width: auto;
      float: none;
      margin: 0 10px 0 0;
    }

    // Active state
    &.active {
      color: $menuActiveColor;
      border-right: 4px solid $menuBorderActiveColor;
      font-weight: 500;
      background-color: inherit;

      &:hover {
        color: $menuBorderActiveColor;
      }
    }
  }
}
