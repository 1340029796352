.save-button {
    position: absolute;
    top: 1.4rem;
    right: 2.3rem;
    border: none;
    border-radius: 5px;
    color: #003057;
    font-size: 0.7rem;
    font-weight: 600;
    padding: 0.6rem 1.2rem;
    cursor: pointer;
}