.image-picker {

  &--error {

  }

  &__copy {

    &--error {
    }
  }

  &__wrap {
  }

  &__img-wrap {
    width: calc(33.33% - 25px);
    margin: 0 10px;
    min-width:90px;

    &:nth-child(3n + 1) {
      margin-left: 0;
    }

    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  &__img {
    &:before, &:after {

    }

    &:before {

    }

    &:after {
    }

    &--selected {

      &:before {
      }

      &:after {
      }
    }
  }
}
