@import '../orders/Fields/GenericFields';

$totalWidth: 50px;
$totalHeight: 32px;
$sliderWidthAndHeight: 27px;
$margin: 2px;
$backgroundColor: #009ABF;

%toggleDisplay {
  position: relative;
  display: inline-block;
  width: $totalWidth;
  min-width: $totalWidth;
  height: $totalHeight;
}

.toggle {
  width: 100%;
  display: flex;

  &__checkbox-wrap {
    @extend %toggleDisplay;
    filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.10));
    display: flex;
    flex-direction: row;
  }

  &__wrap {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__label-wrap {

  }

  &__label {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    @extend %input-label;
    margin-top: 12px;
    margin-bottom: 6px;

    &--error {
      @extend %input-label--error;
    }
  }

  &__checkbox {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .toggle__slider {
      background-color: $backgroundColor;

      &:before {
        transform: translateX($totalWidth - ($sliderWidthAndHeight + $margin * 2));
      }
    }
  }

  &__slider {
    border-radius: $totalHeight;
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 400ms;

    &:before {
      border-radius: 50%;
      position: absolute;
      content: "";
      height: $sliderWidthAndHeight;
      width: $sliderWidthAndHeight;
      left: $margin;
      bottom: $margin;
      background-color: white;
      transition: 300ms;
      box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06);
    }
  }
}
