/* Semantic UI overrides */
.text,
.cog,
.user,
.send,
.pencil,
.power .cloud .download {
  color: var(--color-blue);
}

.ui.breadcrumb {
  font-size: inherit;
}

.ui.breadcrumb a,
.ui.card a,
.ui.cards > .card a {
  color: var(--color-teal);
}

.ui.card > .content {
  height: 100%;
}

.ui.button {
  font-size: 1em;
  font-weight: normal;
  line-height: 21px;
}

.ui.primary.button,
.ui.primary.button:focus {
  background-color: var(--color-teal) !important;
}

.ui.primary.button:hover {
  box-shadow: 0 0 0 1px solid var(--color-gray-dark) !important;
}

.ui.basic.button,
.ui.basic.button:hover,
.ui.basic.buttons .button {
  background: transparent none !important;
  color: var(--color-teal) !important;
  font-weight: 400;
  border-radius: 0.28571429rem;
  text-transform: none;
  text-shadow: none !important;
  box-shadow: 0 0 0 1px var(--color-teal) inset;
}

.ui.breadcrumb > div.section {
  color: var(--color-blue);
}

.ui.pad {
  padding: 2.5em;
}

.ui.nopad,
.ui.nopad > .content {
  padding: 0;
}

.ui.menu {
  border: 0;
}

.ui.radio {
  margin-right: var(--space);
}

.ui.card {
  min-height: 160px;
  max-height: 240px;
  margin: 0;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 9px;
}

.ui > .header {
  font-weight: 500;
  color: var(--color-blue) !important;
}

.ui.header {
  line-height: 42px;
}

.ui.modal > .header:not(.ui) {
  font-weight: 500;
  font-size: 1.7rem !important;
  color: var(--color-blue) !important;
}

.ui.modal > .content .header:not(.ui) {
  color: var(--color-blue) !important;
  font-size: 18px;
}

.ui.modal {
  will-change: opacity;
}

.ui.card > div.header {
  font-size: 1.8em;
  line-height: 28px;
  margin: 0.7rem 0 0 1rem;
  font-weight: normal;
  color: var(--color-gray-dark);
}

.ui.card > div.meta {
  color: var(--color-gray-mid) !important;
  font-size: 16px;
  line-height: 18px;
  margin: 0.5rem 1em 0.1em 1em;
}

.ui.card > div.content > div.meta {
  color: var(--color-gray-mid) !important;
  font-size: 16px;
  line-height: 18px;
}

.ui.card > div.content > div.meta > div.description {
  color: var(--color-gray-dark) !important;
  font-size: 1.3rem;
  line-height: 21px;
  margin-top: 8px !important;
  text-align: left;
}

.ui.card > div.meta > div.description {
  color: var(--color-gray-dark) !important;
  font-size: 1rem;
  line-height: 21px;
  margin-top: 8px !important;
  text-align: left;
}

.ui.form .field.inline {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: var(--space);
  font-size: 16px;
  line-height: 19px;
}

.ui.form .field label,
.ui.form .field.inline > label {
  font-size: 1.14rem;
}

.ui.form .field.disabled {
  opacity: 1;
}

.ui.form input:disabled {
  background-color: var(--color-gray-light);
}

.ui.form section:not(:first-child) {
  border-top: 1px solid var(--color-gray-light);
  margin-top: var(--double-space);
  padding-top: var(--double-space);
}

.ui.input > input {
  min-width: 300px;
}

.ui.checkbox input[type='checkbox'],
.ui.checkbox input[type='radio'],
.ui.checkbox .box:after,
.ui.checkbox .box:before,
.ui.checkbox label:after,
.ui.checkbox label:before {
  width: 25px;
  height: 25px;
}

.ui.checkbox .box,
.ui.checkbox label {
  padding-left: 2.7em;
}

.ui.toggle.checkbox input:checked ~ .box,
.ui.toggle.checkbox input:checked ~ .box:before,
.ui.toggle.checkbox input:checked ~ label:before,
.ui.toggle.checkbox input:checked ~ .box:after {
  background-color: var(--color-teal) !important;
}

.ui.checkbox .box:after,
.ui.checkbox label:after {
  font-size: 20px;
  line-height: 1.2;
}

.ui.selection.dropdown {
  border: 2px solid var(--color-gray-mid);
  border-radius: 0;
}

.ui.dropdown .menu .selected.item,
.ui.dropdown.selected {
  background: #cddce8;
  color: var(--color-blue);
  font-weight: normal;
}

.ui.form .field.error .ui.dropdown,
.ui.form .field.error .ui.dropdown .item,
.ui.form .field.error .ui.dropdown .text,
.ui.form .fields.error .field .ui.dropdown,
.ui.form .fields.error .field .ui.dropdown .item {
  background: unset !important;
}

/* Image Gallery overrides */

.image-gallery-content.fullscreen {
  background: transparent;
  padding-bottom: var(--space);
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: calc(100vh - 90px);
}

.image-gallery-fullscreen-button {
  right: 0;
  top: 0;
  bottom: unset;
}

.image-gallery-svg path {
  d: path(
    'M 2.75 2.042969 L 2.042969 2.75 L 2.398438 3.101563 L 7.292969 8 L 2.042969 13.25 L 2.75 13.957031 L 8 8.707031 L 12.894531 13.605469 L 13.25 13.957031 L 13.957031 13.25 L 13.605469 12.894531 L 8.707031 8 L 13.957031 2.75 L 13.25 2.042969 L 8 7.292969 L 3.101563 2.398438 Z'
  );
}

.drawing-for-file-container {
  max-height: 350px !important;
}

.drawing-for-file-header {
  font-size: 22px !important;
}

.drawing-for-file-image {
  max-height: 240px !important;
}

/* Notification */
.ui.compact.message {
  min-width: 30vw;
}

@mixin table-overrides() {
  background: none;
  background-color: $color-gray-1;
  color: $color-gray-3;
}

.ui.sortable.table {
  thead {
    th {
      &.sorted {
        @include table-overrides;
        &:hover {
          @include table-overrides;
        }
      }
      &:hover {
        @include table-overrides;
      }
      @include table-overrides;
      border-left: none;
    }
  }
}
