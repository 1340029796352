.image-container-small {
    position: relative;
    height: 100%;
    width: 50%;
    cursor: pointer;
}

.image-container-large {
    position: relative;
    cursor: pointer;
}

.image {
    position: relative;
    cursor: pointer;
    box-shadow: -1px 1px 8px rgb(0 0 0 / 41%);
}

.icon-container-small {
    font-size: 1.3em;
    width: 21px;
    background: none;
    color: white;
    position: absolute;
    top: 0px;
    left: 79px;
    padding-left: 2px;
    cursor: pointer;
}

.icon-container-large {
    font-size: 1.5em;
    width: 21px;
    background: none;
    color: white;
    position: absolute;
    top: 0;
    right: 8px;
    padding-left: 2px;
    cursor: pointer;
}
