.segment.visible.transition {
  display: flex !important;
  flex: 1;
  flex-direction: column;

  .projects-table {
    flex: 1;
    overflow: auto;
    border-bottom: 1px solid rgba(34,36,38,.15);

    div.chip {
      overflow: hidden;
    }
  }
}

.project-list {
  height: 100%;
}

// Fix for spaces on top and bottom of the table header visible in small devices
@media only screen and (max-width: 767px) {
  .ui.table:not(.unstackable) thead tr {
    padding-top: 0;
    padding-bottom: 0;
  }
}
