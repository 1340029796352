.vertical.menu.ui.side-menu {
  width: 100%;
  background-color: transparent;

  .logo-container {
    overflow: hidden;
    display: flex;
    align-items: center;
  }

  .side-menu__logo {
    width: 100%;
  }

  .side-menu__item {
    display: flex;
    justify-content: flex-start;
    color: white;
    font-size: 16px;

    .icon {
      margin-right: 15px;
      margin-left: 0;
    }
  }
}

// mobile first
@media screen and (min-width: 950px) {
  .vertical.menu.ui.side-menu {
    .logo-container {
      width: 100%;
    }
  }
}
