$order-font-color:#7f7f7f;
$order-font-title-size:2rem;
$order-font-header-size:1.5rem;
$order-card-width:27.1875rem;
$screen-boundary:116.9285rem;


.orders-tab div:last-child {
  border: none;
  box-shadow: none;
  padding: 0;
}

.delete-icon-btn {
  font-size: 1em;
  color: #777777;
  float: right;
  margin-right: 0.3125rem;
}

.archive-icon-btn {
  font-size: 1em;
  color: #777777;
  float: right;
  margin-left: 0.3125rem;
}

.archive-icon {
  display: contents;
}

.delete-icon {
  display: contents;
}

.tooltiptext {
  visibility: hidden;
  font-size: 13px;
  background-color: #BBBBBB;
  color: #FFFFFF;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
}

.archive-icon .archive-tooltiptext {
  top: -25%;
  left: 75%;
}

.archive {
  width: 100px;
}

.unarchive {
  width: 115px;
}

.delete-icon .delete-tooltiptext {
  width: 100px;
  top: -25%;
  left: 65%;
}

.archive-icon:hover .archive-tooltiptext {
  visibility: visible;
}

.delete-icon:hover .delete-tooltiptext {
  visibility: visible;
}

.order-card-group {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
}

.create-Order {
  margin: 0.76rem 0;
  font-size: 1.14rem;
  width: 100%;
  height: 1.4rem;
}

.order-card-header-title {
  font-weight: 700;
  font-size: $order-font-title-size;
  line-height: 1.81rem;
  color: $order-font-color;
  display: inline-block;
  margin: 0;
  padding: 0;
}

.order-card-text {
  font-weight: 700;
  font-size: $order-font-header-size;
  line-height: 1.79rem;
  color: $order-font-color;
  display: inline-block;
  margin: 0;
  padding: 0;
}

.create-quote-button{
  display: flex;
  font-size: 1.5rem;
  justify-content: center;
  align-items:center
}

.create-quote-box1 {
  color: #fff;
}

.create-quote-box2 {
  color: #000000;
  font-weight: 400;
}

.order-card-header {
  display: flex;
}

.order-feature-card-group {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    gap: 1.25rem;
}

@media (max-width: $screen-boundary) {
  html {
    font-size: 14px;
  }
  .order-feature-card-group {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    gap: 1.25rem;
 }
 .vertical-line {
  display: none;
 }
}

@media (min-width: $screen-boundary) {
  html {
    font-size: 16px;
  }
}

.cover-card-group.cover-empty{
  width: $order-card-width;
  max-height: none;
  padding: 1.65rem 1.5rem !important;
}
.cover-card-group{
  padding: 1.65rem 1.5rem !important;
}

.liner-card-group.liner-empty{
  width: $order-card-width;
  max-height: none;
  padding: 1.65rem 1.5rem !important;
}
.liner-card-group{
  padding: 1.65rem 1.5rem !important;
}

.ui.card.order-card {
  width: $order-card-width;
  max-height: none;
  padding: 1.65rem 1.5rem;
}

.ui.primary.button.create-order-button {
  width: 100%;
  margin-top: 1.25rem;
}