@import '../Fields/GenericFields';

.review-order {
  margin-top: 25px;
  margin-left: 25px;
  padding-bottom: 15px;

  &__sub-title {
    font-size: 24px;
    font-weight: 500;
    color: #003057;
    margin-bottom: 8px;
    margin-top: 24px;
  }

  &__features {
    &--title {
      margin: 25px 0 -10px;
      font-size: 20px;
    }
  }

  &__field {
    margin-top: 16px;
    &__label {
      font-weight: 700;
    }

    &__img{
      max-width:175px;
      height:175px;
      object-fit:cover;
    }
  }

  &__delete {
    padding-top: 1rem;
  }

}
