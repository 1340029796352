@import '../../../Fields/GenericFields';

.shipping-form {
    @extend %order-form-section;
  
    &__title {
      @extend %order-form-section__title;
    }

    &__two-column {
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: 1fr;
      gap: 15px;
    }

    &__address {
      &--title {
        @extend %input-label;
      }
    }
  }