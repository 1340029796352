@import '../../Fields/GenericFields';

.error-field {
  @extend %input-error;

  &--error {
    @extend %input-error--active
  }
  &--margin-bottom {
    margin-bottom: 10px;
  }
}
